import React from 'react'
import { StickyNavbar } from './StickyNavbar'

const Header = () => {
  return (
    <>
    <div className="w-full" id="header">
        <StickyNavbar/>
    </div>
    </>
  )
}

export default Header