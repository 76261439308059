import React from 'react'
import Header from './Header'

import { Outlet } from 'react-router-dom'

const MainLayout = () => {
    return (
        <>
            <div className="container">
                <Header />
                <div className="w-full p-3 min-h-[400px] bg-blue-gray-50">
                    <Outlet />
                </div>
                
            </div>
        </>
    )
}

export default MainLayout