const { default: MainLayout } = require("layouts");
const { default: Dashboard } = require("pages/Dashboard");

export const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <Dashboard />
        },
    ]
}