import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeRoutes } from 'routes';

function App() {
  return (
   <BrowserRouter>
      <ThemeRoutes/>
   </BrowserRouter>
  );
}

export default App;
