import { Input } from '@material-tailwind/react'
import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Dashboard = () => {
    const [services, setService] = useState([]);
    const get_services = async () => {
        await axios.get('https://srjbtkshetraa.org/services/api/services').then(res => {
            setService(res.data.data);
        })
    }
    useEffect(() => {
        get_services();
    }, [])

    const [fdata, setFdata] = useState({});
    const handleFdata = (e) => {
        const arr = { ...fdata };
        const key = e.target.name;
        const value = e.target.value;
        arr[key] = value;
        setFdata(arr);
    }
    const submitform = async() => {
        const data = {...fdata};
        const res = await axios.post('https://srjbtkshetraa.org/services/api/add-recharge', data);
       const url = res.data.payInfo;
       window.location.href = url;
    }
    return (
        <>
            <section>
                <div className="container">
                    <div className="flex flex-wrap">
                        <div className="md:w-1/4 w-full">
                            <div className="form-group px-3 mb-4">
                                <Input onChange={handleFdata} name='name' label='Enter name' value={fdata?.name} />
                            </div>
                        </div>
                        <div className="md:w-1/4 w-full">
                            <div className="form-group px-3 mb-4">
                                <Input onChange={handleFdata} name='mobile' label='Enter Mobile' value={fdata?.mobile} />
                            </div>
                        </div>
                        <div className="md:w-1/4 w-full">
                            <div className="form-group px-3 mb-4">
                                <select onChange={handleFdata} name='service_id' className="p-2 w-full rounded-md text-gray-700 bg-blue-gray-50 border border-blue-gray-200 outline-none">
                                    <option value="">---Select Service---</option>
                                    {
                                        services.map((sr) => (
                                            <option value={sr.id}>{sr.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="md:w-1/4 w-full">
                            <div className="form-group px-3 mb-4">
                                <Input onChange={handleFdata} name='amount' label='Enter Amount' value={fdata?.amount} />
                            </div>
                        </div>
                        <div className="md:w-1/4 w-full">
                            <div className="form-group px-3 mb-4">
                                <button onClick={submitform} className="bg-gray-800 p-2 w-full shadow-md shadow-blue-gray-800 rounded-md text-white">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard